.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: var(--black-light);
}

.logo {
  @media (max-width: 768px) {
    width: 130px !important;
  }
}

.nav-btns {
  display: flex;
  gap: 1rem;
  color: white;
}
