/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/560bcc4e09749527-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d77e36fa1e563bf0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/51fbb2aa5ef2b5cf-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7da2a3ff16027414-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2cffe11a47028c14-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4cf1eda6528c4545-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/037c6ceced78c87a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1520da2fce335b3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/84c22514fc6f1667-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5ea4559b0835633-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8bbb723f6276338f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_ab9b34';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/996798fe8aee0dc3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Bai_Jamjuree_Fallback_ab9b34';src: local("Arial");ascent-override: 96.17%;descent-override: 24.04%;line-gap-override: 0.00%;size-adjust: 103.99%
}.__className_ab9b34 {font-family: '__Bai_Jamjuree_ab9b34', '__Bai_Jamjuree_Fallback_ab9b34';font-style: normal
}

:root {
  --primary: #08b4c0;
  --primary-dark: #222b2c;
  --success: #52a400;
  --warning: #ff8c21;
  --danger: #eb5757;
  --white: #ffffff;
  --black: #231f20;
  --black-light: #2b2b2b;

  --gray-100: #f5f5f5;
  --gray-200: #e8e8e8;
  --gray-300: #d9d9d9;
  --gray-400: #bfbfbf;
  --gray-500: #999999;
  --gray-600: #595959;
  --gray-700: #434343;
  --gray-800: #363333;

  --font-semi-bold: 400;
  --font-bold: 700;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--black);
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--gray-200);
}

pre {
  color: var(--white);
}

input,
select,
textarea {
  font-size: medium;
  padding: 0.8rem;
  margin-top: 0.15rem;
  background: var(--white);
  border: 1px solid var(--gray-500);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  margin: 6px;
  padding: 0;
  background-color: transparent;
  transition: outline 0.2s;
  cursor: pointer;
}

input[type="checkbox"]:active {
  outline: none;
}

input[type="checkbox"]:checked {
  background-size: cover;
  padding: 2px;
}

input[type="checkbox"]:not(:disabled):checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 32 32" xml:space="preserve"><path style="fill: %2308b4c0" d="M11.941,28.877l-11.941-11.942l5.695-5.696l6.246,6.246l14.364-14.364L32,8.818"/></svg>');
}

input[type="file"] {
  display: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

::placeholder,
::-ms-input-placeholder {
  font-weight: 400;
  color: var(--gray-400);
  opacity: 1;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

textarea {
  resize: none;
  height: 8rem;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--black);
  outline: none;
}

input.error,
select.error,
textarea.error {
  border: 1px solid var(--danger);
}

button {
  font-weight: lighter;
  font-size: 0.8rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.25rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  background: var(--primary);
  color: var(--white);
}

button:hover:not(:disabled) {
  filter: brightness(0.8);
}

button:active:not(:disabled) {
  filter: brightness(0.6);
}

button:disabled {
  cursor: not-allowed;
  background: var(--gray-200);
  color: var(--gray-400);
}

.btn-success:disabled {
  cursor: not-allowed;
  background: var(--success);
  opacity: 0.5;
}

table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
  overflow-x: auto;
}

th,
td {
  min-width: 100px;
  max-width: 100px;
  font-size: 0.9rem;
  block-size: -moz-fit-content;
  block-size: fit-content;
  overflow: hidden;
  text-align: center;
  padding: 1.3rem 0.65rem;
  border: 0.5px solid var(--gray-600);
}

th,
table th:first-child {
  background-color: var(--primary-dark) !important;
  border: 0.5px solid var(--gray-600);
}

td {
  font-weight: lighter;
  font-size: 0.9rem;
  padding: 0;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: var(--primary);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--gray-100);
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

::-webkit-scrollbar-thumb:active {
  background: var(--primary-dark);
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(128, 128, 128, 0.4) !important;
}

.loader {
  border: 2.5px solid var(--gray-300);
  border-top: 2.5px solid var(--primary);
  border-radius: 100%;
  width: 22px;
  height: 22px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dashboard-container {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1.5rem;
  padding-bottom: 20rem;
  background-color: var(--black);
  color: var(--white);
}

.dashboard-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dashboard-content-header h1 {
  font-size: 1.65rem;
  line-height: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: unset;
  }
}

.shadow {
  box-shadow: 0px 4px 8px 0px rgba(26, 26, 26, 0.3);
}

.btn-sm {
  font-size: 0.75rem;
  padding: 0.48rem 1.15rem;
  border-radius: 0.5rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  border-radius: 0.25rem;
}

.btn-success {
  background-color: var(--success);
  color: var(--white);
}

.btn-dark {
  background-color: var(--black);
  border: 1px solid var(--gray-600);
  color: var(--white);
}

.btn-dark-light {
  background-color: var(--gray-600);
  color: var(--white);
}

.btn-white {
  background-color: var(--white);
  color: var(--primary);
}

.btn-danger {
  background-color: var(--danger);
  color: var(--white);
}

.btn-danger-white {
  background-color: var(--white);
  color: var(--danger);
}

.btn-clear {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}

.btn-clear:disabled {
  opacity: 0.75;
  cursor: not-allowed;
  background-color: transparent;
}

.txt-bold {
  font-weight: bold !important;
}

.txt-primary {
  color: var(--primary);
}

.txt-success {
  color: var(--success);
}

.txt-danger {
  color: var(--danger);
}

.txt-lighter {
  font-weight: lighter;
}

.txt-sm {
  font-size: 0.8rem;
}

.txt-center {
  text-align: center;
}

.styles_container__CyEiD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: var(--black-light);
}

.styles_logo__6Snco {
  @media (max-width: 768px) {
    width: 130px !important;
  }
}

.styles_nav-btns__e5HQU {
  display: flex;
  gap: 1rem;
  color: white;
}

